<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Holiday'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/hrholiday`" title="Holiday" :columns="columns"
                routerpath="/master/hr/holiday/addholiday" :formOptions="formOptions" :newPage="true" :edit="edit"
                :add="add" :canDelete="canDelete" :viewFields="viewFields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
import TabPosition from "../../../../components/TabView.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
  },
  data() {
    return {
      edit: "",
      add: "",
      canDelete: "",
      // tabs: [
      //   { name: 'Holiday', route: '/master/hr/holiday', icon: 'CalendarIcon' },
      //   { name: 'HolidayName', route: '/master/hr/holidayname', icon: 'BarChart2Icon' },
      //   { name: 'Region', route: '/master/hr/regionmaster', icon: 'CrosshairIcon' },
      //   { name: 'ApplyPost', route: '/master/hr/applypost', icon: 'HardDriveIcon' },
      //   { name: 'Asset', route: '/master/hr/assetmaster', icon: 'CompassIcon' },
      //   { name: 'Cast', route: '/master/hr/cast', icon: 'CastIcon' },
      //   { name: 'Document', route: '/master/hr/document', icon: 'FileIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Holiday" : "Add Holiday"}`,
        // submitButtonName: "Add Holiday Master",
        submitRouterPath: "/master/hr/holiday",
        gridForm: true,
        method: "post",
        action: "add",
        url: `${baseApi}/hrholiday`,
        getEditValue: `${baseApi}/gethrholidayById`,
        inputFields: [],
      },
      viewFields: [
        {
          label: 'Holiday Name',
          field: 'holidayname',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Date',
          field: 'date',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'State',
          field: 'state',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Details',
          field: 'details',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Region Master',
          field: 'region',
          type: 'text',
          class: 'col-md-4'
        },
      ],
      columns: [
        {
          label: "Holiday Name",
          field: "holidayname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Holiday Name",
          },
        },
        {
          label: "Date",
          field: "date",
          type: "datetime",

          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "State",
          field: "state",
          filterOptions: {
            enabled: true,
            placeholder: "Search State",
          },
        },
        {
          label: "Details",
          field: "details",
          filterOptions: {
            enabled: true,
            placeholder: "Search Details ",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Holiday") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;

          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
